<template>
  <div>
    <v-row class="d-flex justify-center mb-0 light-blue lighten-5">
      <v-col cols="8">
        <v-card
          elevation="2"
          class="pa-2"
        >
          <v-row>
            <v-col>
              <div class="primary--text text-h5 text-center mt-2 font-weight-thin">
                Blanket Licenses
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="primary--text body mt-2">
                Resident State Licenses
              </div>
              <div class="black--text body mt-2 font-weight-thin">
                Yeroosha Inc. DBA Blanket Insurance National Producer # 19770563 <br>
                FEIN: 38-4159587<br>
                Florida (Resident) License #L111488
              </div>
              <div class="primary--text text-body-1 mt-2">
                Non-Resident State Licenses
              </div>
              <div class="black--text text-body-1 mt-2 font-weight-thin">
                Alabama - Property, Casualty, Life - #3001157970<br>
                **Alaska - Life - #3001163946<br>
                Arizona - Property, Casualty, Life, Travel - #3001157951 Arkansas - Property, Casualty, Life - #3001157981<br>
                California - Property, Casualty, Life - #6004048<br>
                Colorado - Property, Casualty, Life #676554<br>
                Connecticut - Property, Casualty, Life, Credit, Travel - #2709883 Delaware – Producer, Travel - #3001186274<br>
                District of Columbia - Property, Casualty, Life - #3001157988Pen Georgia - Property, Casualty, Life - #216341<br>
                Hawaii - Property, Casualty, Life - #527269<br>
                Idaho - Property, Casualty, Life - #826898<br>
                *Illinois - #3001157954<br>
                Indiana - Property, Casualty, Life - #3631554<br>
                *Iowa - #3001158171<br>
                *Kansas - #384159587-000<br>
                Kentucky - Property, Casualty, Life - #1118306<br>
                Louisiana - Property, Casualty, Life - # 905750<br>
                Maine - Property, Casualty, Life - #373953<br>
                *Maryland - #3001160593<br>
                Massachusetts – Property, Casualty, Life - #2142960 *Michigan - #0131009<br>
                *Minnesota - #40728066<br>
                *Mississippi - #15039885<br>
                *Missouri - #3001160593<br>
                Montana - Property, Casualty, Life - #3001186866 *Nebraska - #3001158076<br>
                Nevada - Property, Casualty, Life - #3632236<br>
                New Hampshire - Property, Casualty, Life - #3001158057 New Jersey - Property, Casualty, Life - #3001166035 New Mexico - Property, Casualty, Life - #3001160111 New York - Property, Casualty, Life - #1645625<br>
                *North Carolina - # 3001158137<br>
                North Dakota - Property, Casualty, Life - #3001158026 Ohio - Property, Casualty, Life - #1343970<br>
                Oklahoma - Property, Casualty, Life - #3001158046 Oregon - Property, Casualty, Life - #3001158799 **Pennsylvania - Life - # 989715<br>
                Rhode Island - Property, Casualty, Life #3001158051 *South Carolina - #3001158069<br>
                South Dakota - Property, Casualty, Life - #10027933 *Tennessee - #3001158149<br>
                Texas - Property, Casualty - #2754730 Texas - Life #2627552<br>
                Utah - Property, Casualty, Life - #826401 *Vermont - #3630259<br>
                Virginia - Property, Casualty, Life - #150564<br>
                Washington - Property, Casualty, Life, Travel - #1088650 *West Virginia - #3001159568<br>
                *Wisconsin - #3001158053<br>
                Wyoming - Property, Casualty, Life - #454327<br>
                *Line of Authority is not indicated on business entity licensing. LOA is based on responsible producer’s licensing.<br>
                **P&C Line of Authority is Pending Issuance by State<br>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        destination: 'Destination 1',
        primaryDestination: 'p1',
        living: 'State 1',
        primaryTraveler: 'Jane',
        secondTraveler: '',
        tripCost: '1',
        coverage: '',
        age: 40,
        numberOfTravelers: 1,
        coverageOptions: ['Small', 'Medium', 'Large'],
        startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        checkbox: true,
        modal1: null,
        modal2: null,
        places: ['State 1', 'State 2', 'State 3', 'State 4'],
        menu1: false,
        menu2: false,
        numbers: [1, 2],
        destinations: [
          'Destination 1',
          'Destination 2',
          'Destination 3',
          'Destination 4'
        ],
        primaryDestinations: ['p1', 'p2', 'p3', 'p4']
      }
    },
    computed: {
      travelDetails () {
        return {
          destination: this.destination,
          age: this.age,
          primaryDestination: this.primaryDestination,
          living: this.living,
          primaryTraveler: this.primaryTraveler,
          secondTraveler: this.secondTraveler,
          tripCost: this.tripCost,
          numberOfTravelers: this.numberOfTravelers,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
    },
    methods: {
      async next () {
        const complete = await this.$refs.observer.validate()
        if (!complete) {
          console.log('Error validating form')
        } else {
          this.$store.dispatch('setTravelDetails', this.travelDetails)
          this.$router.push({ path: '/travelcoverageoptionscanada' })
        }
      // post form data to api
      }
    }
  }
</script>

<style lang="scss" scoped></style>
